.App-header {
    background-color: #24305e;
    color: #FFFFFF;
    font-family: 'Avenir Next', monospace;
    font-size: medium;
    letter-spacing: .2em;
    pointer-events: none;
}

.App-footer {
    background-color: #24305e;
    color: #FFFFFF;
    font-family: 'Avenir Next', monospace;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    right: 100%;
}

a span{
  color: #FFFFFF;
}

.Content {
  margin-top: 2em;
}

.header-color:first-child {
  background-color: #FFB81C;
  font-family: 'Avenir Next', serif;
  font-size: medium;
}

.body-color {
  font-family: 'Avenir Next', sans-serif;
  font-size: medium;
}

.header-color {
  background-color: #FFB81C;
  font-family: 'Avenir Next', serif;
  font-size: medium;
}

hr {
  background-color: #FFFFFF;
}

.language {
  max-height: 40px;
}

.row.display-flex {
    display: flex;
    flex-wrap: wrap;
}

.row.display-flex > [class*='col-'] {
    display: flex;
    flex-direction: column;
}

.terminal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -65%);
    height: 40%;
    width: 40%;
}

.enter {
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, 150px);
}


.btn {
    font-family: 'Avenir Next', monospace;
}